<template>
  <div class="pricing-page">
    <Redirect title="Account" route="website.settings.account" />
    <h2 class="mt-2 mb-4" style="font-size: 32px">Pick a plan for your store</h2>
    <SkeletonLoaders type="card-heading, list-item-three-line" v-if="loading" />
    <div v-else>
      <h3 class="mb-2">More Features, Support &amp; Security With No Hidden Fees</h3>
      <div class="text-center mb-6" v-if="trialInfo.status">
        LatteX isn’t your thing? Cancel before
        <span>
          {{ endTimeTrial }}
        </span>
        and no payment needed
      </div>
      <v-row v-if="subscriptionList.length" class="justify-center">
        <v-col v-for="(item, index) in subscriptionList" :key="index" cols="12" sm="6" md="4">
          <SubscriptionVue :subscriptionData="item" @choosePlan="showDialogConfirm = true"></SubscriptionVue>
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center" v-else>
        <span class="title">No Data</span>
      </div>
    </div>
    <v-dialog v-model="showDialogConfirm" :width="500" transition="dialog-transition">
      <v-card class="pa-4" v-if="showDialogConfirm">
        <div class="d-flex justify-space-between align-center mb-2">
          <h3>Review your subscription</h3>
          <v-btn icon @click="showDialogConfirm = false"> <v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-space-between align-center my-3">
          Package: <span class="font-weight-bold">{{ subscription.name }}</span>
        </div>
        <div class="d-flex justify-space-between align-center">
          Price: <span class="font-weight-bold">{{ subscription.price }}</span>
        </div>
        <v-divider class="my-3"></v-divider>
        <p v-if="isTrial.status">
          After the trial ends, you will pay
          <span class="font-weight-bold">{{ subscription.price }} on {{ isTrial.endTimeAt }}</span> for the cycle from
          {{ isTrial.endTimeAt }} to {{ isTrial.endTimeOfSubscription }}.
        </p>
        <p>This is a secure 128-bit SSL Encrypted payment.<b> You're safe</b></p>
        <v-btn :loading="loadingConfirm" color="primary" :height="40" width="100%" depressed @click="handleConfirm"
          >Start plan</v-btn
        >
      </v-card>
    </v-dialog>
    <v-snackbar v-model="message.status" :timeout="2500" :color="message.type" top>{{ message.title }}</v-snackbar>
  </div>
</template>

<script>
import Redirect from '@/components/RedirectTo';
import SubscriptionVue from './components/Subscription.vue';
import moment from 'moment';
export default {
  components: {
    Redirect,
    SubscriptionVue,
  },
  data() {
    return {
      loading: true,
      showDialogConfirm: false,
      loadingConfirm: false,
      message: {
        status: false,
        title: null,
        type: 'success',
      },
    };
  },
  computed: {
    subscriptionList() {
      return this.$store.state.subscription.subscriptionList;
    },
    trialInfo() {
      return this.$store.state.subscription.trialInfo;
    },
    endTimeTrial() {
      return moment()
        .add(this.trialInfo.dayLeft, 'days')
        .format('MMM D, YYYY');
    },
    subscription() {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return {
        name: this.$store.state.subscription.subscriptionSelected?.name || 'No subscription',
        price: formatter.format(this.$store.state.subscription.subscriptionSelected?.config.platformLattexFee.sub || 0),
      };
    },
    isTrial() {
      const endTimeAt = moment().add(this.$store.state.subscription.trialInfo.dayLeft, 'days');
      return {
        status: this.$store.state.subscription.trialInfo.status,
        endTimeAt: endTimeAt.format('MMM D, YYYY'),
        endTimeOfSubscription: endTimeAt.add(1, 'M').format('MMM D, YYYY'),
      };
    },
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        await this.$store.dispatch('getCurrentSubscription');
        await this.$store.dispatch('getListSubscription');
      } catch (error) {
        console.log('🚀 ~ file: index.vue:41 ~ getData ~ error:', error);
      } finally {
        this.loading = false;
      }
    },
    showMessage(_type, _message) {
      this.message.type = _type;
      this.message.message = _message;
      this.message.status = true;
    },
    async handleConfirm() {
      try {
        this.loadingConfirm = true;
        const result = await this.$store.dispatch('confirmPlan');
        if (result) {
          this.$router.push({ name: 'website.settings.account' });
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:119 ~ handleConfirm ~ error:', error);
        this.showMessage('error', 'Error');
      } finally {
        this.loadingConfirm = false;
        this.showDialogConfirm = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.pricing-page h3 {
  font-size: 24px !important;
  letter-spacing: -0.25px !important;
  line-height: 30px !important;
  text-align: center;
}
</style>
